<template v-if="isDataLoaded">
  <div class="p-4 bg-white rounded-lg">
    <dashboard-layout
      :dashboards="dashboards"
      :updateDashboardData="updateDashboardData"
      :filters="filters"
    ></dashboard-layout>
  </div>
</template>

<script>
import DashboardLayout from "@/own/components/dashboard/DashboardLayout.vue";
import { UPDATE_DASHBOARD_DATA } from "@/core/services/store/dashboardReturn.module";

export default {
  name: "Return",
  components: { DashboardLayout },
  methods: {
    updateDashboardData: async function (
      datas = { date_range_type: "this_month", date_range: null }
    ) {
      if (datas.date == "custom") {
        await this.$store.dispatch(UPDATE_DASHBOARD_DATA, datas);
      } else {
        await this.$store.dispatch(UPDATE_DASHBOARD_DATA, datas);
      }
    },
  },
  computed: {
    dashboards: function () {
      return this.$store.getters.getRETURNDASHBOARDTableData;
    },
    filters: function () {
      // console.log("filters are ", this.$store.getters.getRETURNDASHBOARDFilter);
      return this.$store.getters.getRETURNDASHBOARDFilter;
    },
    isDataLoaded: function () {
      return (
        this.$store.getters.getRETURNDASHBOARDTableData.length > 0 &&
        this.$store.getters.getRETURNDASHBOARDFilter.length > 0
      );
    },
  },
  beforeMount() {
    this.updateDashboardData();
  },
};
</script>

<style scoped></style>
