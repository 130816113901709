var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-content-height pb-8 poppins"},[_c('div',{staticClass:"row my-0 mx-1 px-4"},[_c('div',{staticClass:"poppins text-dark-65 font-size-h1 display5-lg font-weight-bolder col-12 col-md-2 px-0"},[_vm._v(" My orders ")]),_c('div',{staticClass:"col-12 col-md-10 d-flex justify-end align-center flex-wrap"},[_c('FilterSelector',{attrs:{"filters":_vm.filters,"update-data-of-charts":_vm.updateDataOfCharts}}),_c('div',{staticClass:"col-12 col-md-3 ma-0 pa-0"},[_c('div',[_c('v-select',{staticClass:"px-2",attrs:{"items":_vm.dateTypes,"item-text":"text","item-value":"value","label":"Date","solo":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',[_vm._v("mdi-calendar-range")])]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-btn',{staticClass:"ma-0 pa-0 w-100",attrs:{"text":"","large":""},on:{"click":function($event){return _vm.updateDateOfCharts(item.value)}}},[_c('div',{staticClass:"w-100 text-left px-2"},[_vm._v(_vm._s(item.text))])])]}}]),model:{value:(_vm.dateTypeTrigger),callback:function ($$v) {_vm.dateTypeTrigger=$$v},expression:"dateTypeTrigger"}}),_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-date-picker',{attrs:{"range":"","scrollable":"","max":new Date().toISOString().split('T')[0]},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":() => {
                    _vm.dialog = false;
                    _vm.isVisible = true;
                    _vm.dateTypeTrigger = _vm.dateType;
                  }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.updateDateOfCharts('custom')}}},[_vm._v(" OK ")])],1)],1)],1)])],1)]),(_vm.showData)?_c('div',{staticClass:"d-flex row mx-1 justify-space-between pt-12 px-6"},_vm._l((_vm.dashboards),function(item,index){return _c('div',{key:`chart_${index}`,staticClass:"ma-0 py-0 px-1",class:{
        'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 pb-2':
          item.type == 'counter' ? true : false,
        'col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6':
          item.type == 'column' || item.type == 'donut' ? true : false,
        'col-12': item.type == 'area' || 'label' ? true : false,
      }},[(item.type == 'column')?_c('fulfillment-per-country-column-chart',{staticClass:"mt-4",attrs:{"item":item,"filterData":_vm.dataOfFilters()}}):_vm._e(),(item.type == 'donut')?_c('FulfillmentPerformanceDonutChart',{staticClass:"mt-4",attrs:{"item":item,"filterData":_vm.dataOfFilters()}}):_vm._e(),(item.type == 'counter')?_c('fulfillment-orders-counter-chart',{attrs:{"item":item,"filterData":_vm.dataOfFilters()}}):_vm._e(),(item.type == 'area')?_c('OrdersProgressAreaChart',{staticClass:"mt-4",attrs:{"item":item,"filterData":_vm.dataOfFilters()}}):_vm._e(),(item.type == 'label')?_c('dashboard-label',{attrs:{"item":item}}):_vm._e()],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }