import { render, staticRenderFns } from "./Return.vue?vue&type=template&id=15918600&scoped=true&v-if=isDataLoaded"
import script from "./Return.vue?vue&type=script&lang=js"
export * from "./Return.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15918600",
  null
  
)

export default component.exports