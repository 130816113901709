<template>
  <v-card class="ma-0 pa-0 d-block mt-4" v-if="isDataLoaded">
    <v-card-title class="h3 text-uppercase py-2">{{ item.title }}</v-card-title>
    <v-card-text class="d-block ma-0 pa-0" style="height: 438px !important">
      <apexchart
        type="bar"
        :options="chartOptions"
        :series="series"
        height="438"
      ></apexchart>
    </v-card-text>
  </v-card>
  <v-skeleton-loader v-else type="card"></v-skeleton-loader>
</template>

<script>
// import { getToken } from "@/core/services/jwt.service";
// import axios from "axios";
// import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  name: "FulfillmentPerCountryColumnChart",
  props: ["item", "filterData"],
  data: () => ({ serverData: null }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      let data = this.filterData;
      ApiService.post(`${this.item.url}`, data)
        .then((response) => {
          this.serverData = response.data.chart;
        })
        .catch(() => {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.serverData;
    },
    chartOptions: function () {
      return {
        chart: {
          type: "bar",
          width: "100%",
          stacked: true,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "60%",
            distributed: true,
            endingShape: "round",
            rangeBarGroupRows: true,
            rangeBarOverlap: true,
          },
        },
        stroke: {
          lineCap: "round",
        },

        fill: {
          colors: this.serverData.values.color1,
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            gradientToColors: this.serverData.values.color2,
            shade: "dark",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: true,
          borderColor: "#FFFFFF",
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },

          row: {
            colors: ["#ECECEC", "transparent"],
            opacity: 0.8,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },

        xaxis: {
          categories: this.serverData.values.categories,
          labels: {
            rotate: 0,
            style: {
              // colors: this.serverData.values.color1,
              fontSize: "10px",
            },
          },
        },
      };
    },
    series: function () {
      let series = this.serverData.values.series.map((serie) => {
        return serie.data[0];
      });
      return [{ data: series, name: "" }];
    },
  },
  watch: {
    filterData() {
      this.serverData = null;
      this.loadDataFromServer();
    },
  },
};
</script>
