<template>
  <div class="pt-4 pb-4" style="">
    <div class="h3 font-weight-bold text-uppercase">{{ item.title }}</div>
  </div>
</template>

<script>
export default {
  name: "DashboardLabel",
  props: ["item"],
};
</script>
