<template>
  <div class="custom-content-height pb-8 poppins">
    <div class="row my-0 mx-1 px-4">
      <div
        class="poppins text-dark-65 font-size-h1 display5-lg font-weight-bolder col-12 col-md-2 px-0"
      >
        My orders
      </div>
      <div class="col-12 col-md-10 d-flex justify-end align-center flex-wrap">
        <FilterSelector
          :filters="filters"
          :update-data-of-charts="updateDataOfCharts"
        ></FilterSelector>
        <div class="col-12 col-md-3 ma-0 pa-0">
          <div>
            <v-select
              class="px-2"
              v-model="dateTypeTrigger"
              :items="dateTypes"
              item-text="text"
              item-value="value"
              label="Date"
              solo
              dense
              hide-details
            >
              <template v-slot:prepend-inner>
                <v-icon>mdi-calendar-range</v-icon>
              </template>
              <!-- <template v-slot:item="{ item }"
                >
                <div
                  style="width: 100%"
                  @click="updateDateOfCharts(item.value)"
                >
                  {{ item.text }}
                </div>
              </template> -->
              <template v-slot:item="{ item }"
                ><v-btn
                  text
                  large
                  class="ma-0 pa-0 w-100"
                  @click="updateDateOfCharts(item.value)"
                >
                  <div class="w-100 text-left px-2">{{ item.text }}</div>
                </v-btn>
              </template></v-select
            >
            <v-dialog ref="dialog" v-model="dialog" persistent width="290px">
              <v-date-picker
                v-model="dateRange"
                range
                scrollable
                :max="new Date().toISOString().split('T')[0]"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="
                    () => {
                      dialog = false;
                      isVisible = true;
                      dateTypeTrigger = dateType;
                    }
                  "
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="updateDateOfCharts('custom')"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex row mx-1 justify-space-between pt-12 px-6"
      v-if="showData"
    >
      <div
        class="ma-0 py-0 px-1"
        :class="{
          'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 pb-2':
            item.type == 'counter' ? true : false,
          'col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6':
            item.type == 'column' || item.type == 'donut' ? true : false,
          'col-12': item.type == 'area' || 'label' ? true : false,
        }"
        v-for="(item, index) in dashboards"
        :key="`chart_${index}`"
      >
        <fulfillment-per-country-column-chart
          class="mt-4"
          v-if="item.type == 'column'"
          :item="item"
          :filterData="dataOfFilters()"
        ></fulfillment-per-country-column-chart>
        <FulfillmentPerformanceDonutChart
          class="mt-4"
          v-if="item.type == 'donut'"
          :item="item"
          :filterData="dataOfFilters()"
        ></FulfillmentPerformanceDonutChart>
        <fulfillment-orders-counter-chart
          v-if="item.type == 'counter'"
          :item="item"
          :filterData="dataOfFilters()"
        ></fulfillment-orders-counter-chart>
        <OrdersProgressAreaChart
          class="mt-4"
          v-if="item.type == 'area'"
          :item="item"
          :filterData="dataOfFilters()"
        ></OrdersProgressAreaChart>
        <dashboard-label v-if="item.type == 'label'" :item="item">
        </dashboard-label>
      </div>
    </div>
  </div>
</template>

<script>
import FulfillmentOrdersCounterChart from "@/own/components/dashboard/FulfillmentOrdersCounterChart.vue";
import FulfillmentPerformanceDonutChart from "@/own/components/dashboard/FulfillmentPerformanceDonutChart.vue";
import OrdersProgressAreaChart from "@/own/components/dashboard/OrdersProgressAreaChart.vue";
import FulfillmentPerCountryColumnChart from "@/own/components/dashboard/FulfillmentPerCountryColumnChart.vue";
import DashboardLabel from "@/own/components/dashboard/DashboardLabel.vue";
import FilterSelector from "@/own/components/dashboard/filters/FilterSelector";
export default {
  name: "DashboardLayout",
  props: ["dashboards", "updateDashboardData", "filters"],
  components: {
    FilterSelector,
    DashboardLabel,
    FulfillmentOrdersCounterChart,
    FulfillmentPerformanceDonutChart,
    OrdersProgressAreaChart,
    FulfillmentPerCountryColumnChart,
  },
  data: () => ({
    dialog: false,
    dateTypeTrigger: "this_month",
    dateType: "this_month",
    isVisible: true,
    filterData: {},
    dateRange: [],

    dateTypes: [
      { text: "Today", value: "today" },
      { text: "This week", value: "this_week" },
      { text: "Last week", value: "last_week" },
      { text: "Last 7 days", value: "last_7_days" },
      { text: "This month", value: "this_month" },
      { text: "Last month", value: "last_month" },
      { text: "Last 30 days", value: "last_30_days" },
      { text: "This year", value: "this_year" },
      { text: "Last year", value: "last_year" },
      { text: "All time", value: "all_time" },
      { text: "Custom", value: "custom" },
    ],
  }),
  methods: {
    dataOfFilters() {
      const filterData = {
        date_range_type: this.dateType,
        date_range: this.dateRange,
        ...this.filterData,
      };
      return filterData;
    },
    updateDataOfCharts(val) {
      this.filterData[`${Object.entries(val)[0][0]}`] =
        Object.entries(val)[0][1];
      let data = { ...this.filterData };
      this.filterData = { ...data };
      this.updateDashboardData({
        date_range_type: this.dateType,
        date_range: this.dateRange,
        ...this.filterData,
      });
    },
    updateDateOfCharts(val) {
      if (this.dateType != val) {
        this.isVisible = false;
      }
      if (val === "custom") {
        if (!this.dialog) {
          this.dialog = true;
          this.isVisible = false;
          return;
        } else {
          this.dialog = false;

          this.dateType = this.dateTypeTrigger;
          this.updateDashboardData({
            date_range_type: val,
            date_range: this.dateRange,
            ...this.filterData,
          });
          this.isVisible = true;
          return;
        }
      } else {
        this.dateTypeTrigger = val;
        this.dateType = this.dateTypeTrigger;
        this.updateDashboardData({
          date_range_type: this.dateType,
          date_range: this.dateRange,
          ...this.filterData,
        });
        this.isVisible = true;
      }
    },
  },
  computed: {
    showData: function () {
      return this.isVisible;
    },
    isClient: function () {
      return !this.$store.getters.currentUser.data.is_client;
    },
  },
};
</script>
