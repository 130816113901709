<template>
  <v-card class="ma-0 pa-0 d-block" v-if="isDataLoaded">
    <v-card-title class="h3 text-left text-uppercase py-2">{{
      item.title
    }}</v-card-title>
    <v-card-text
      class="d-block ma-0 pa-0"
      style="max-height: 470px !important; min-height: 198px; height: auto"
    >
      <apexchart
        style=""
        type="radialBar"
        :options="chartOptions"
        :series="series"
        ref="chart"
      ></apexchart>
    </v-card-text>
  </v-card>
  <v-skeleton-loader v-else type="card"></v-skeleton-loader>
</template>

<script>
// import { getToken } from "@/core/services/jwt.service";
// import axios from "axios";
// import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
export default {
  name: "FulfillmentPerformanceDonutChart",
  props: ["item", "filterData"],
  data: () => ({
    serverData: null,
  }),
  beforeMount() {
    this.loadDataFromServer();
    // window.addEventListener("resize", () => {
    //   this.makeChartNormal();
    // });
  },
  updated() {
    this.makeChartNormal();
  },
  methods: {
    makeChartNormal() {
      let series = this.series;
      for (let i = 0; i < series.length; i++) {
        if (series[i] < 0.5) {
          // const el = `apexcharts-radialbarTrack-${i}`;
          // let element = document.getElementById(el);
          // console.log(i, element.attributes["stroke-opacity"], element);
          // element.setAttribute("stroke-opacity", "0");
          let series = this.series;
          for (let i = 0; i < series.length; i++) {
            if (series[i] < 0.5) {
              let pathTag = document.getElementsByClassName(
                "apexcharts-radialbar-area apexcharts-radialbar-slice-" + i
              )[0];
              pathTag.setAttribute("stroke-opacity", "0");
              pathTag.style.display = "none";
              // console.log(pathTag, i);
            }
          }
        }
      }
    },
    toggleChart() {
      // const tracks = document.getElementsByClassName("apexcharts-tracks");
      // console.log(tracks);
      // this.$refs.chart.toggleSeries(this.serverData.values.labels[index]);
    },
    loadDataFromServer() {
      let data = this.filterData;
      ApiService.post(`${this.item.url}`, data)
        .then((response) => {
          this.serverData = response.data.chart;
        })
        .catch(() => {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.serverData;
    },
    series: function () {
      return this.serverData.values.series;
    },
    chartOptions: function () {
      return {
        chart: {
          // redrawOnWindowResize: false,
          // redrawOnParentResize: false,
          parentHeightOffset: 0,
          // sparkline: {
          //   enabled: true
          // },
          type: "radialBar",
          height: 500,
          width: 500,
          toolbar: {
            show: true,
            tools: {
              download: true,
            },
          },
          events: {
            // mounted: (chart) => {
            //   chart.windowResizeHandler();
            // },
            mounted: function (chartContext, options) {
              chartContext.windowResizeHandler();
              let series = options.config.series;
              for (let i = 0; i < series.length; i++) {
                if (series[i] < 0.5) {
                  let pathTag = document.getElementsByClassName(
                    "apexcharts-radialbar-area apexcharts-radialbar-slice-" + i
                  )[0];
                  pathTag.style.display = "none";
                  // console.log(pathTag, i);
                }
              }
            },
          },
        },
        height: 500,
        width: 500,
        colors: this.serverData.values.colors,
        legend: {
          onItemClick: {
            toggleDataSeries: false,
          },
          show: true,
          inverseOrder: true,
          floating: true,
          fontSize: "18px",
          position: "right",
          offsetX: -10,
          offsetY: 247,
          labels: {
            useSeriesColors: true,
          },
          itemMargin: {
            horizontal: "8",
            vertical: "0.7",
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        markers: {
          size: 12,
        },
        labels: this.serverData.values.labels,
        fill: {
          colors: this.serverData.values.colors,
          type: "",
        },
        stroke: {
          lineCap: "round",
          curve: "straight",
        },
        plotOptions: {
          radialBar: {
            width: 500,
            height: 500,
            offsetY: 0,
            startAngle: -180,
            endAngle: 90,
            hollow: {
              rounded: true,
              margin: 0,
              size: "12%",
              background: "transparent",
              image: undefined,
              position: "front",
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            track: {
              rounded: true,
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: "#eaeaea",
              strokeWidth: "100%",
              opacity: 1,
              margin: 3,
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        yaxis: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 3840,
            options: {
              chart: {
                type: "radialBar",
                height: 500,
                width: 500,
                events: {
                  mounted: function (chartContext, options) {
                    let series = options.config.series;
                    for (let i = 0; i < series.length; i++) {
                      if (series[i] < 0.5) {
                        let pathTag = document.getElementsByClassName(
                          "apexcharts-radialbar-area apexcharts-radialbar-slice-" +
                            i
                        )[0];
                        pathTag.style.display = "none";
                        // console.log(pathTag, i);
                      }
                    }
                  },
                },
              },
              legend: {
                show: true,
                inverseOrder: true,
                floating: true,
                fontSize: "18px",
                position: "right",
                offsetX: -10,
                offsetY: 247,
                labels: {
                  useSeriesColors: true,
                },
                itemMargin: {
                  horizontal: "8",
                  vertical: "0.7",
                },
              },
            },
          },
          {
            breakpoint: 1980,
            options: {
              chart: {
                type: "radialBar",
                height: 500,
                width: "500",
                events: {
                  mounted: function (chartContext, options) {
                    let series = options.config.series;
                    for (let i = 0; i < series.length; i++) {
                      if (series[i] < 0.5) {
                        let pathTag = document.getElementsByClassName(
                          "apexcharts-radialbar-area apexcharts-radialbar-slice-" +
                            i
                        )[0];
                        pathTag.style.display = "none";
                        // console.log(pathTag, i);
                      }
                    }
                  },
                },
              },
              legend: {
                show: true,
                inverseOrder: true,
                floating: true,
                fontSize: "18px",
                position: "right",
                offsetX: -10,
                offsetY: 247,
                labels: {
                  useSeriesColors: true,
                },
                itemMargin: {
                  horizontal: "8",
                  vertical: "0.7",
                },
              },
            },
          },
          {
            breakpoint: 1245,
            options: {
              chart: {
                type: "radialBar",
                height: 500,
                width: 500,
                events: {
                  mounted: function (chartContext, options) {
                    let series = options.config.series;
                    for (let i = 0; i < series.length; i++) {
                      if (series[i] < 0.5) {
                        let pathTag = document.getElementsByClassName(
                          "apexcharts-radialbar-area apexcharts-radialbar-slice-" +
                            i
                        )[0];
                        pathTag.style.display = "none";
                        // console.log(pathTag, i);
                      }
                    }
                  },
                },
              },
              legend: {
                show: true,
                inverseOrder: true,
                floating: true,
                fontSize: "18px",
                position: "right",
                offsetX: -10,
                offsetY: 247,
                labels: {
                  useSeriesColors: true,
                },
                itemMargin: {
                  horizontal: "8",
                  vertical: "0.7",
                },
              },
            },
          },
          {
            breakpoint: 970,
            options: {
              chart: {
                type: "radialBar",
                height: 500,
                width: 500,
                events: {
                  mounted: function (chartContext, options) {
                    let series = options.config.series;
                    for (let i = 0; i < series.length; i++) {
                      if (series[i] < 0.5) {
                        let pathTag = document.getElementsByClassName(
                          "apexcharts-radialbar-area apexcharts-radialbar-slice-" +
                            i
                        )[0];
                        pathTag.style.display = "none";
                        // console.log(pathTag, i);
                      }
                    }
                  },
                },
              },
              legend: {
                show: true,
                inverseOrder: true,
                floating: true,
                fontSize: "18px",
                position: "right",
                offsetX: -10,
                offsetY: 247,
                labels: {
                  useSeriesColors: true,
                },
                itemMargin: {
                  horizontal: "8",
                  vertical: "0.7",
                },
              },
            },
          },
          {
            breakpoint: 580,
            options: {
              chart: {
                type: "radialBar",
                height: 320,
                width: 320,
                events: {
                  mounted: function (chartContext, options) {
                    let series = options.config.series;
                    for (let i = 0; i < series.length; i++) {
                      if (series[i] < 0.5) {
                        let pathTag = document.getElementsByClassName(
                          "apexcharts-radialbar-area apexcharts-radialbar-slice-" +
                            i
                        )[0];
                        pathTag.style.display = "none";
                        // console.log(pathTag, i);
                      }
                    }
                  },
                },
              },
              legend: {
                show: true,
                inverseOrder: true,
                floating: true,
                fontSize: "10px",
                position: "right",
                offsetX: -10,
                offsetY: 148,
                labels: {
                  useSeriesColors: true,
                },
                itemMargin: {
                  horizontal: "8",
                  vertical: "0.7",
                },
              },
            },
          },
          {
            breakpoint: 380,
            options: {
              chart: {
                type: "radialBar",
                height: 260,
                width: 260,
                events: {
                  mounted: function (chartContext, options) {
                    let series = options.config.series;
                    for (let i = 0; i < series.length; i++) {
                      if (series[i] < 0.5) {
                        let pathTag = document.getElementsByClassName(
                          "apexcharts-radialbar-area apexcharts-radialbar-slice-" +
                            i
                        )[0];
                        pathTag.style.display = "none";
                        // console.log(pathTag, i);
                      }
                    }
                  },
                },
              },
              legend: {
                show: true,
                inverseOrder: true,
                floating: true,
                fontSize: "8px",
                position: "right",
                offsetX: -10,
                offsetY: 112,
                labels: {
                  useSeriesColors: true,
                },
                itemMargin: {
                  horizontal: "0",
                  vertical: "-0.8",
                },
              },
            },
          },
        ],
      };
    },
  },
  watch: {
    filterData() {
      this.serverData = null;
      this.loadDataFromServer();
    },
  },
};
</script>
