<template>
  <v-card class="ma-0 pa-0 d-block" v-if="isDataLoaded">
    <v-card-title class="h3 text-uppercase py-2">{{ item.title }}</v-card-title>
    <v-card-text>
      <apexchart
        type="area"
        height="400"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </v-card-text>
  </v-card>
  <v-skeleton-loader v-else type="image"></v-skeleton-loader>
</template>

<script>
// import { getToken } from "@/core/services/jwt.service";
// import axios from "axios";
// import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
export default {
  name: "OrdersProgressAreaChart",
  props: ["item", "filterData"],
  data: () => ({ serverData: null }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      let data = this.filterData;
      ApiService.post(`${this.item.url}`, data)
        .then((response) => {
          this.serverData = response.data.chart;
        })
        .catch(() => {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.serverData;
    },
    series: function () {
      return this.serverData.values.series;
    },
    chartOptions: function () {
      return {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#A100FF"],
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "category",
          categories: this.serverData.values.categories,
        },
        tooltip: {},
      };
    },
  },
  watch: {
    filterData() {
      this.serverData = null;
      this.loadDataFromServer();
    },
  },
};
</script>
